<template>
  <div class="members-directory">
    <ApiErrorMessage :message="apiErrorMessage" />
    <div class="panel-inr-box">
      <div class="box-top-wrap box-mobile-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <button @click="$router.push({ name: 'VehicleHistory' })" class="text-capitalize
              btn customBtn pattern-btn
              white--text
            " elevation="0">
            <span>{{ $t("carHistory.back") }}</span>
          </button>
          <h3 class="box-title green-text">
            {{ $t("carHistory.previousCarHistory") }}
          </h3>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.width > 500" />
        <v-text-field hide-details="auto" v-model="search" elevation="0" height="40" dense
          :placeholder="$t('posts.searchForWords')" solo @input="fetchPreviousCarHistory"
          append-icon="mdi-magnify"></v-text-field>
      </div>
      <div class="custom-card">
        <div class="custom-box">
          <v-data-table :header-props="{ sortIcon: null }" :headers="headers" :items="previousCarHistory.data"
            :single-expand="singleExpand" :expanded.sync="expanded" fixed-header :disable-sort="true"
            :show-expand="false" class="color-light-green" :loading="loading" :page.sync="page"
            :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
            <!--  <template v-slot:item.pdf_path="{ item }">
              <span v-if="item.pdf_path"
                ><a class="view_receipt" @click="downlaodPDF(item.pdf_path)">
                  <v-icon size="35" class="green--text text--darken-4 mr-4"
                    >mdi-arrow-down-bold-circle</v-icon
                  ></a
                ></span
              >
            </template>-->
            <template v-slot:item.created_at="{ item }">
              <span class="datebreak date-time">
                <v-icon aria-hidden="false">
                  mdi-clock-time-five-outline
                </v-icon>
                <span>{{ item.created_at | DateTimezoneFilter }}</span>
              </span>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ (item.amount == 1) ? 'Premium VIN Decoder' : 'New Car History' }}
            </template>
            <template v-slot:item.pdf_path="{ item }">
              <span v-if="item.pdf_path">
                <!-- <a class="view_receipt" target="_blank" :href="item.pdf_path">View</a> -->
                <v-btn height="35" class="btn customBtn pattern-btn
                        text-capitalize white-text" @click="viewReport(item)"><span>{{ $t("messages.view") }}</span></v-btn>
              </span>
            </template>
          </v-data-table>
          <div class="text-center custom-pagination">
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p> {{ $t("pagination.showing") }} {{ startEntry }} {{ $t("pagination.to") }} {{ endEntry }} {{ $t("pagination.of") }} {{ totalEntries }} {{ $t("pagination.entries") }}</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  {{ $t("pagination.previous") }}
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  {{ $t("pagination.next") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";
export default {
  name: "PreviousVehicleHistory",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",


      loading: false,
      page: 1,
      search: "",
      id: null,
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      headers: [
        {
          text: this.$t("carHistory.date"),
          align: "left",
          value: "created_at",
        },
        {
          text: this.$t("carHistory.vinNumber"),
          align: "left",
          value: "vin_number",
        },
        {
          text: this.$t("carHistory.type"),
          align: "left",
          value: "amount",
        },
        {
          text: this.$t("carHistory.pdfPath"),
          align: "left",
          value: "pdf_path",
        },
      ],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },


    ...mapGetters({
      previousCarHistory: "carHistory/getPreviousCarHistoryReport",
    }),
  },
  async mounted() {
    await this.fetchPreviousCarHistory();
  },

  methods: {
    ...mapActions({
      getPreviousCarHistoryReport: "carHistory/getPreviousCarHistoryReport",
    }),

    downlaodPDF(url) {
      let link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.dispatchEvent(new MouseEvent("click"));
    },


    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchPreviousCarHistory(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchPreviousCarHistory(); // Fetch new data based on the current page
      }
    },

    async fetchPreviousCarHistory() {
      this.loading = true;
      try {
        if (this.search !== "") {
          this.page = 1;
        }
        await this.getPreviousCarHistoryReport({
          search: this.search,
          page: this.currentPage,
          items_per_page: this.perPage,
        });

        if (this.previousCarHistory.meta) {
          this.totalEntries = this.previousCarHistory.meta.total;
          this.itemsPerPage = parseInt(this.previousCarHistory.meta.per_page);
          this.pageCount = this.previousCarHistory.meta.last_page;
        } else {
          this.itemsPerPage = this.previousCarHistory.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    viewReport(item) {
      this.$store.state.vehicleHistory.vinHtmlReport = { data: item };
      // this.$store.state.vehicleHistory.setVinHtmlReport = {data: item};
      this.$router.push({ name: "VehicleHistoryResult" });
    }
  },
};
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
